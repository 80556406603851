<!-- 我要提现 -->
<template>
    <div class="withdrawal w100">
        <el-card class="box-card  w100">
            <div class="balanceCardCss">
                <balanceCard :userInfo="userInfo" :showWithdrawalButton="false"></balanceCard>
                <el-divider></el-divider>
            </div>

            <div class="ZFBInfo">
                <p class="text"><i class="el-icon-star-on colorF40"></i>1.确认收款账号</p>
                <p class="ZFBNumber" v-if="userInfo.u_ali_account">支付宝账号：{{ userInfo.u_ali_account }} </p>
                <p class="ZFBName" v-if="userInfo.u_ali_account">支付宝实名：{{
                    userInfo.u_ali_realname }} <el-button type="text" @click="showSetZfb">修改收款支付宝</el-button></p>

            </div>
            <div class="coPriceFrom">
                <p class="text"><i class="el-icon-star-on colorF40"></i>2.提现金额</p>
                <el-form :model="form" :rules="rules" ref="form" class="demo-ruleForm" width="30%">
                    <el-form-item prop="co_price">
                        <p class="flex">
                            <el-input v-model="form.co_price" placeholder="请输入需要提现的金额"></el-input><el-button type=""
                                @click="allWithdrawal">全部提现</el-button>
                        </p>
                    </el-form-item>
                </el-form>
            </div>
            <div> <el-button class="withdrawalButton" type="primary" @click="withdrawalButton">确认提现 </el-button></div>

        </el-card>
        <setZFB :show="show" :userInfo="userInfo" @closeZFBFrom="closeZFBFrom"></setZFB>
    </div>
</template>

<script>
import balanceCard from '../component/balanceCard.vue'
import setZFB from '../component/setZFB.vue';
export default {
    data() {
        return {
            show: false,
            userInfo: {},
            form: { co_price: '' },
            rules: {
                co_price: [
                    { required: true, message: '请输入提现金额', trigger: 'blur' },
                ]
            }
        };
    },

    components: {
        setZFB,
        balanceCard
    },

    mounted() {
        this.getUserinfo()
    },

    methods: {
        allWithdrawal() {
            this.form.co_price = this.userInfo.ua_balance

        },
        closeZFBFrom() {
            this.show = false
            this.getUserinfo()
        },
        showSetZfb() {
            this.show = true
        },
        async withdrawalButton() {
            await this.$refs['form'].validate()
            let res = await this.$axios
                .post("/api/account/cash", this.form)
            if (res.code == "000") {
                this.$message.success("提交成功,请耐心等待!");
                this.$router.push({
                    path: '/memberCenter/withdrawalRecord'
                })
            } else if (res.code == "162") {
                this.$confirm('超过单日提现额度，可前往实名认证提高额度', '限额提醒', {
                    confirmButtonText: '前往实名',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        this.$router.push({ name: 'realName' })
                    })
                    .catch(() => {

                    });
            } else {
                this.$message.error(res.msg);
            }
        },
        getUserinfo() {
            this.$axios
                .get("/api/user/getInfo")
                .then((res) => {
                    console.log(res);
                    this.userInfo = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    }
}

</script>
<style lang="scss" scoped>
.withdrawal {

    .box-card {
        width: 90%;
    }

    .balanceCardCss {
        width: 60%;
    }

    .ZFBInfo {
        margin-top: 15px;

        .ZFBNumber {
            font-size: 15px;
        }

        .ZFBName {
            font-size: 15px;
        }
    }

    .coPriceFrom {
        margin-top: 15px;
        width: 40%;
    }

    .withdrawalButton {
        margin-left: 100px;
    }

    .text {
        font-size: 18px;
        color: #686868;
        margin-bottom: 20px;
    }

    .colorF40 {
        color: #f40;
    }

}
</style>